<template>
  <div class="dg-module-form" v-if="amReady">
    <div class="dg-module-container text-capitalize mb-2" v-if="formHeader">
      <h5>{{ this.getFormTitle }}</h5>
    </div>

    <div class="dg-module-container p-3">
    <el-row>
      <el-col :class="$langConfig.cssClass" :span="(!editMode)? 4 : 4">
        <dg-storageSingleFile
            :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
            :allowed-ext="['sql', 'png','jpg', 'jpeg', 'txt', 'pdf', 'wav', 'mp3', 'mp4', 'xlsx', 'pptp', 'pptpx', 'doc', 'docx', 'rar', 'zip']"
            :field_span="24"
            :file-size-limit="12"
            :label_span="24"
            :viewEvent="false"
            :downloadEvent="downloadEventFromUploader"
            :rules="(editMode)? '' : 'required'"
            field_mark="required"
            :translateLabel="false"
            label="file"
            name="file"></dg-storageSingleFile>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="mt-2 border-top">
        <el-col :class="$langConfig.cssClass" :span="12" class="mt-4">
          <label class="error-field-validate-message text-capitalize"
                 v-show="!this.validData">{{ (requestErrorMessage) ? requestErrorMessage : $ml.get('fields_do_not_match_the_required_criteria') }}</label>
        </el-col>
        <el-col :span="12" class="pt-3 text-right">
          <dg-confirm-button
              :loading="requestOn"
              :onConfirm="moduleDeleteAction"
              :translate="false"
              style="margin-right: 10px"
              confirmButtonType="danger"
              fieldSize="mini"
              icon="el-icon-delete"
              popIcon="el-icon-delete"
              text="remove"
              title="sure to removing this record ?"
              type="danger"
              v-if="editMode"></dg-confirm-button>

          <dg-button :click="cancelEditable" class="mr-2" fieldSize="mini" icon="el-icon-close" text="cancel"
                     type="warning" v-if="editable && editMode"></dg-button>
          <dg-button :click="changeEditable" fieldSize="mini" icon="el-icon-edit" text="edit" type="success"
                     v-if="!editable && editMode"></dg-button>

          <dg-button :click="createModule" :icon="actionButtonIcon" :loading="requestOn" :text="actionButtonLabel"
                     :translate="false"
                     :type="actionButtonType" fieldSize="mini"
                     v-if="editable"></dg-button>

          <!--<dg-confirm-button
              :icon="actionButtonIcon"
              :loading="requestOn"
              :onConfirm="createModule"
              :text="actionButtonLabel"
              :translate="false"
              :type="actionButtonType"
              fieldSize="mini"
              v-if="editable"></dg-confirm-button>-->
        </el-col>
      </el-col>
    </el-row>
    </div>
  </div>
</template>

<script>
import module from './../index.js'

export default {
  beforeMount() {this.getFormData()},
  data() {
    return {
      module: {...module},
      control: this,
      amReady: false,
      requestOn: false,
      editable: !this.editMode,
      requestErrorMessage: null,
      radioOptions: [
        {
          label: 'Yes',
          value: 1,
          link_label: ''
        },
        {
          label: 'No',
          value: 0,
          link_label: ''
        }
      ],
    }
  },
  methods: {
    getFormData() {
      let promises = []
      promises.push(this.getEntityDropDownList().then())
      Promise.all(promises).then((values) => {
        this.amReady = true
        $vue.nextTick(() => {this.bindController()})
      })
    },
    ...$mapActions('Storage-File-api', {
      getEntityDropDownList: 'getDropDownList',
      updateFile: 'updateFile',
      updateImage: 'updateImage',
      updateGallery: 'updateGallery',
      removeGalleryImage: 'removeGalleryImage'
    }),
     removeFromGallery (file) {
      let params = {
        id: this.dataToBind.id,
        fileId: file.id,
        parentId: file.parent_id
      }
      this.removeGalleryImage(params).then(r => {
          if (this.onEditCallback) {
            this.onEditCallback(r.data, 'gallery', false)
          }

          $services['notify']({
            type: 'success',
            title: 'Picture Removed',
            position: 'topRight',
          })
      })
    },
    async uploadFile (entityId, fieldName, apiName) {
      if (this.dataModel.hasOwnProperty(fieldName) && this.dataModel[fieldName]) {
        let data =  {}
        data[fieldName] = this.dataModel[fieldName]

        let fileParams = {data, id: entityId}
        await this[apiName](fileParams).then(r => {
          $services['notify']({
            type: 'success',
            title: 'File Uploaded',
            position: 'topRight',
          })
        }).catch(error => {
          $services['notify']({
            type: 'error',
            title: 'File Not Uploaded',
            position: 'topRight',
          })
        })
      }
    },
    async uploadGallery (entityId) {
      if (this.dataModel.hasOwnProperty('gallery') && this.dataModel.gallery) {
        let galleryParams = {
          data: {
            gallery: this.dataModel.gallery
          },
          id: entityId
        }

        await this.updateGallery(galleryParams).then(r => {
          $services['notify']({
            type: 'success',
            title: 'Gallery Uploaded',
            position: 'topRight',
          })
        }).catch(error => {
          $services['notify']({
            type: 'error',
            title: 'Gallery Not Uploaded',
            position: 'topRight',
          })
        })
      }
    },
    async createModule() {
      this.validateFields().then( async result => {
        if (!result) { this.requestErrorMessage = null; return}

        this.requestOn = true
        let context = this;
        let apiMethod = (this.editMode) ? 'update' : 'create';
        let apiParams = {data: {...this.dataModel}};
        if (this.editMode) apiParams.id = this.dataToBind.id;

        /*delete apiParams.data.file
        delete apiParams.data.picture
        delete apiParams.data.gallery

        if (apiMethod == 'update') {
            await this.uploadFile(this.dataToBind.id, 'file', 'updateFile') // Updating File
            await this.uploadFile(this.dataToBind.id, 'picture', 'updateImage') // Updating Picture
            await this.uploadGallery(this.dataToBind.id) // Updating Gallery
        }*/

        this.$store.dispatch('Storage-File-api/' + apiMethod, apiParams).then( async response => {
          let entity = response.data
          this.amReady = false
          $services['notify']({
            type: 'success',
            title: (apiMethod == 'update') ? 'Updated' : 'Created',
            position: 'topRight',
          })

           if (apiMethod == 'create') {
             await this.uploadFile(entity.id, 'file', 'updateFile') // Updating File
             await this.uploadFile(entity.id, 'picture', 'updateImage') // Updating Picture
             await this.uploadGallery(entity.id) // Updating Gallery
          }

          $vue.nextTick(() => {
            context.amReady = true
            if (context.editMode) {
              if (context.onEditCallback) context.onEditCallback(response.data)
            } else {
              if (context.onCreateCallback) context.onCreateCallback(response.data)
            }
          })

        }).catch(error => {
          console.log('catch error', error)
          this.validData = false
          if (error.response.data.errorMessage) {
            $store.state['App-App-state'].generalDialogStatus = true
            $store.state['App-App-state'].generalDialogMessage = error.response.data.errorMessage
            $store.state['App-App-state'].generalDialogTitle = "Attention"

            this.requestErrorMessage = error.response.data.errorMessage
          } else {
            this.setfieldsErrorMessage({
              fieldsErrors: error.response.data
            });
          }
        }).finally(() => {this.requestOn = false})
      })
    }
  },
  mixins: [$mixins['fieldController'], $mixins['moduleForm']],
}
</script>
